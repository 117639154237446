@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: NunitoSans-Regular;
  src: local('NunitoSans-Regular'), url(/src/fonts/NunitoSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: NunitoSans-Bold;
  src: local('NunitoSans-Bold'), url(/src/fonts/NunitoSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: NunitoSans-SemiBold;
  src: local('NunitoSans-SemiBold'), url(/src/fonts/NunitoSans-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: Belleza-Regular;
  src: local('Belleza-Regular'), url(/src/fonts/Belleza-Regular.ttf) format("truetype");
}

@layer components {
  .row {
    @apply flex flex-row
  }

  .col {
    @apply flex flex-col
  }

  .custom-select {
    @apply relative
  }

  /* width */
  .scroll-notification::-webkit-scrollbar {
    @apply w-1;
  }

  /* Handle */
  .scroll-notification::-webkit-scrollbar-thumb {
    @apply bg-green-grey;
  }

  /* Track */
  .scroll-notification::-webkit-scrollbar-track {
    @apply bg-dark-grey-two;
  }

  .order-table th{
    @apply font-black !important;
  }

  .order-table th:first-child{
    @apply rounded-l-md;
  }

  .order-table th:last-child{
    @apply rounded-r-md;
  }

  .print-label-grid {
    @apply grid grid-cols-2 gap-3 py-1 pr-5 pl-2
  }

  .box-content-style {
    @apply rounded-lg border border-light-grey-four bg-white
  }
}
