@media all {
    .page-break {
        display: none;
    }
}
@media print {
    .size-a6 {
        padding-top: 10px;
        position: relative;
        page: size-a6;
    }
}

@media print {
    .page-break {
        margin-top: 3rem;
        display: block;
        page-break-before: auto;
    }
}

@page size-a6{
    size: 105mm 148mm;
    margin: 0;
}
